import { template as template_3ff606e3ee9949338bc4a8d918beafc2 } from "@ember/template-compiler";
const FKText = template_3ff606e3ee9949338bc4a8d918beafc2(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;

import { template as template_1f7241c996804662bf76ae4dc0c53708 } from "@ember/template-compiler";
const SidebarSectionMessage = template_1f7241c996804662bf76ae4dc0c53708(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;

import { template as template_e80decebb14b49e598e81ac7d7432821 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const RepliesCell = template_e80decebb14b49e598e81ac7d7432821(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="posts"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="replies"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default RepliesCell;

import { template as template_aa40c66600e14da88b1a3b4d837bfe4c } from "@ember/template-compiler";
const FKControlMenuContainer = template_aa40c66600e14da88b1a3b4d837bfe4c(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;

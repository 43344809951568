import { template as template_c30c06abe5ce4f7e9841c666058b0220 } from "@ember/template-compiler";
const WelcomeHeader = template_c30c06abe5ce4f7e9841c666058b0220(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;

import { template as template_b000b44e43fa4d7089d7ae13416604b5 } from "@ember/template-compiler";
const FKLabel = template_b000b44e43fa4d7089d7ae13416604b5(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
